import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateDailogComponent } from 'src/shared/components/dialog/admin-dialogs/candidate-dialog/candidate-dialog.component';
import { OpenPositionAdminService } from '../../services/open-position.service';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { PageEvent } from '@angular/material/paginator';
import { ViewAdminJobPopupComponent } from './view-job-detail/view-job-detail.component';
import { ViewFeedbackDialogComponent } from 'src/shared/components/dialog/view-feedback-dialog/view-feedback-dialog.component';
import { Sort } from '@angular/material/sort';
import { FilterCandidateSidebarComponent } from './filter-sidebar/filter-candidate.component';

export interface PeriodicElement {
  name: string;
  email: string;
  notice: string;
  resume: string;
  application: string;
  status: string;
  action: string;
}

@Component({
  selector: 'app-code-craft',
  templateUrl: './codecraft-specialist.component.html',
})
export class CodeCraftComponent {
  id!: string;
  candidateList: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'jobTitle' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  form!: FormGroup;
  filterStatus: Set<string> = new Set();
  totalRecords: number = 0;
  lastSortBy: string = '';
  loading: boolean = false;
  element: any;
  canManageResources: boolean = false;
  isFilterApplied: boolean = false;
  selectedStatus: string[] = [];
  selectedNoticePeriod: string[] = [];

  @ViewChild('viewJobDetail') viewJobComponent!: ViewAdminJobPopupComponent;
  @ViewChild('filter')
  filterComponent!: FilterCandidateSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private candidateService: OpenPositionAdminService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) { }

  openViewJobDrawer(): void {
    if (this.viewJobComponent) {
      this.viewJobComponent.isOpen = !this.viewJobComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    this.loadOpenPosiionPermissions();

    this.id = this.route.snapshot.params['id'];
    this.getCandidateList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
      status: [''],
    });
    this.searchCandidate();
  }

  loadOpenPosiionPermissions(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const openPositionPermissions = userPermissions.find(
        (p: any) => p.module === 'open_position'
      );
      if (
        openPositionPermissions &&
        openPositionPermissions.permission.includes('view') &&
        openPositionPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      } else {
        this.canManageResources = false;
      }
    } else {
      this.canManageResources = false;
    }
  }

  isStatusInterview(status: string): boolean {
    return !!status && status.toLowerCase() === 'interview';
  }

  isStatusSelected(status: string): boolean {
    return !!status && status.toLowerCase() === 'selected';
  }

  isStatusScreening(status: string): boolean {
    return !!status && status.toLowerCase() === 'screening';
  }

  isStatusRejected(status: string): boolean {
    return !!status && status.toLowerCase() === 'rejected';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'screening':
        return 'Screening';
      case 'interview':
        return 'Interview';
      case 'selected':
        return 'Selected';
      case 'rejected':
        return 'Rejected';
      default:
        return '';
    }
  }

  displayedColumns: string[] = [
    'name',
    'email',
    'notice',
    'resume',
    'application',
    'status',
    'action',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  setElement(selectedElement: any): void {
    this.element = selectedElement;
    if (this.viewJobComponent) {
      this.viewJobComponent.id = selectedElement.id;
    }
  }

  viewFeedback(): void {
    const dialogRef = this.dialog.open(ViewFeedbackDialogComponent, {
      height: 'auto',
      width: '467px',
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getCandidateList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }

  getCandidateList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.candidateService
      .getCandidateList(pageNumber, limit, search, sortBy, orderBy, this.id)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.candidateList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Candidate List request:', error);
          this.loading = false;
        },
      });
  }

  getCandidateListByStatus(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedStatus: string[] = []
  ): void {
    this.loading = true;
    const statusName = selectedStatus.join('&status=');
    this.candidateService
      .getCandidateList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.id,
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.candidateList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Candidate List request:', error);
          this.loading = false;
        },
      });
  }

  getCandidateListByNoticePeriod(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedNoticePeriod: string[] = []
  ): void {
    this.loading = true;
    const noticePeriod = selectedNoticePeriod.join('&noticePeriodRanges=');
    this.candidateService
      .getCandidateList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.id,
        '',
        noticePeriod
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.candidateList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Candidate List request:', error);
          this.loading = false;
        },
      });
  }

  getCandidateListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedStatus: string[] = [],
    selectedNoticePeriod: string[] = []
  ): void {
    this.loading = true;
    const statusName = selectedStatus.join('&status=');
    const noticePeriod = selectedNoticePeriod.join('&noticePeriodRanges=');

    this.candidateService
      .getCandidateList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.id,
        statusName,
        noticePeriod
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.candidateList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Candidate List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    this.selectedStatus = filters.selectedStatus || [];
    this.selectedNoticePeriod = filters.selectedNoticePeriod || [];

    if (this.selectedStatus.length > 0 && this.selectedNoticePeriod.length === 0) {
      this.getCandidateListByStatus(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedStatus
      );
    } else if (this.selectedNoticePeriod.length > 0 && this.selectedStatus.length === 0) {
      this.getCandidateListByNoticePeriod(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedNoticePeriod
      );
    } else if (this.selectedNoticePeriod.length > 0 && this.selectedStatus.length > 0) {
      this.getCandidateListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedStatus,
        this.selectedNoticePeriod
      );
    } else {
      this.getCandidateList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onCandidateListSort(event: Sort) {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';
    const statusName = this.selectedStatus.join('&status=');
    const noticePeriod = this.selectedNoticePeriod.join('&noticePeriodRanges=');

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.candidateService
      .getCandidateList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.id,
        statusName,
        noticePeriod
      )
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records || [];
          this.dataSource.data = this.candidateList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in User List request:', error);
        },
      });
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(CandidateDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false, positionID: this.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCandidateList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(CandidateDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, element: element, positionID: this.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCandidateList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  searchCandidate(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCandidateList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  downloadResume(resumePath: string): void {
    if (!resumePath) {
      return;
    }

    this.candidateService.getResume(resumePath).subscribe(
      (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.extractFileName(resumePath);
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading resume:', error);
      }
    );
  }

  private extractFileName(path: string): string {
    const parts = path.split('/');
    return parts[parts.length - 1];
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  goBack() {
    this.router.navigate(['open-positions']);
  }
}
